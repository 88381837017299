<div class="form-group">
  <label [className]="customLabelClass">{{label}}{{isRequired ? '*' : ''}}</label>
  @if(multi){
    <mat-select [formControl]="fControl" multiple>
      <mat-option value="" disabled>{{placeholder || 'Selecione'}}</mat-option>
      @for (option of options; track $index) {
        <mat-option [value]="option.value">{{option.label}} {{additionalKey ? '- ' + option.value[additionalKey] : ''}}</mat-option>
      }
    </mat-select>





  } @else {
    <mat-select [formControl]="fControl" (selectionChange)="change.emit($event)">
      <mat-option value="" disabled>{{placeholder || 'Selecione'}}</mat-option>
      @for (option of options; track $index) {
        <mat-option [value]="option.value">{{option.label}} {{additionalKey ? '- ' + option.value[additionalKey] : ''}}</mat-option>
      }
    </mat-select>





  }
  <ng-content select="[sub]"></ng-content>
  @if(fControl.invalid && fControl.touched){
    <span class="error">{{errorMessage}}</span>
  }

</div>
