import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'fw-select',
  standalone: true,
  imports: [
    MatSelectModule,
    ReactiveFormsModule,
    CommonModule
  ],
  templateUrl: './fw-select.component.html',
  styleUrl: './fw-select.component.scss'
})
export class FwSelectComponent {

  @Input() label: string = '';
  @Input() options: IOption[] = [];
  @Input() fControl: any;
  @Input() multi: boolean = false;
  @Input() placeholder: string = '';
  @Input() additionalKey: string = '';


  @Input() showRequired: boolean = true;
  @Input() customLabelClass: string = '';

  @Output() change: EventEmitter<any> = new EventEmitter();

  get errorMessage() {
    let message = 'Selecione um valor válido';
    if (this.fControl.hasError('required')) {
      message = 'Esse campo é obrigatório';
    }

    return message;
  }

  get isRequired() {
    if(!this.showRequired)
      return false;
    if(!this.fControl?.validator){
      return false;

    }
    const validators = this.fControl.validator({} as AbstractControl);
    if (validators && validators.required) {
      return true;
    }
    return false;
  }
}

export interface IOption {
  value: any;
  label: string;
}
